import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import CardBenefits from '../components/afi/CardBenefits'
import LayoutsComponent from '../layouts/LayoutsComponent';
import SimuladorPrimeraChamba from "../components/pages/TusDatosPrimeraChamba";


const TusDatosPrimeraChamba = ({ pageContext, location }) => {
  return (
    <LayoutsComponent
      layout={pageContext.layout}
      pageContext={pageContext}
      location={location}
      gradient
      menu
      companyName={pageContext.company}
      broker={pageContext.broker}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <SimuladorPrimeraChamba pageContext={pageContext} />
          <br />
          {pageContext.company === "AFI" ?
            <CardBenefits /> : ''}
        </>
      )}
    />
  )
}

export default TusDatosPrimeraChamba